.container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.boxActivities {
    width: 40%;
    height: 600px;
}

.boxBtn button {
    width: 300px;
    height: 90px;
    font-size: 2.4rem;
}