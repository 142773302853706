.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px 0 10px;
    width: 920px;
    height: 500px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.boxError {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30px;
    font-size: 1.6rem;
}

.boxForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 360px;
}

.boxInputs {
    width: 48%;
}

.boxTextarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.boxTextarea p {
    margin: 10px 0 10px 5px;
    font-size: 2rem;
}

.boxTextarea textarea {
    padding: 10px;
    font-size: 2rem;
    border: 2px solid var(--primary-text);
    border-radius: 10px;
    color: var(--primary-text);
}

.btnBox {
    display: flex;
    justify-content: space-between;
    width: 50%;
}