.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0 10px;
    width: 860px;
    height: 500px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.box h2 {
    margin-bottom: 10px;
    font-size: 2.6rem;
    letter-spacing: 1px;
}

.boxForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    height: 80%;
}

.boxInputName {
    width: 100%;
}

.boxInput {
    width: 48%;
}

.boxBtn {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

.pError {
    padding-bottom: 10px;
    font-size: 1.8rem;
    letter-spacing: 1px;
}