.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 10px;
    width: 800px;
    height: 720px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.boxItems {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 70%;
    height: 90%;
}

.formBox {
    width: 100%;
}

.name {
    margin: 0 0 10px 30px;
    font-size: 2rem;
    color: var(--primary-text);
}

.dataBox {
    display: flex;
    justify-content: space-between;
    width: 100%;

}

.inputSelect,
.select {
    padding: 10px 15px;
    width: 65%;
    border: 2px solid var(--primary-text);
    border-radius: 10px;
    color: var(--primary-text);
    font-size: 2rem;
    outline: none;
}

.inputSelect :focus,
.select:focus {
    border-color: var(--primary-color);
}

.inputSelect {
    cursor: pointer;
}

.select {
    margin-bottom: 15px;
    padding: 6px 10px;
    font-size: 1.8rem;
    cursor: pointer;
}