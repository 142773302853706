.container {
    display: flex;
    justify-content: space-between;
}

.boxActivities {
    width: 40%;
    height: 600px;
}

.boxInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    margin-top: 20px;
}

.boxBtn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
}

.boxBtn button {
    width: 300px;
    height: 90px;
    font-size: 2.4rem;
}

.boxInfo h2 {
    margin: 20px 0;
    font-size: 2.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--primary-text);
}

.boxInfo ul {
    list-style: none;
    font-size: 2.2rem;
    font-weight: 500;
}

.boxInfo ul li {
    display: flex;
    justify-content: space-between;
    width: 360px;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    color: var(--primary-text);
    cursor: pointer;
}

.boxInfo ul li:hover {
    color: var(--primary-color);
}