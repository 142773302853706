.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0 10px;
    width: 980px;
    height: 780px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.equipmentBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 20px 0 40px;
    padding: 20px 0;
    height: 84%;
    overflow-y: scroll;
}

.formBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 90%;
}

.boxItem {
    display: flex;
    width: 48%;
}

.btnBox {
    display: flex;
    justify-content: space-between;
    width: 40%;
}

.alertText {
    font-size: 1.6rem;
    font-weight: 500;
    color: red;
}

.btnForm {
    margin-left: 20px;
    padding: 10px 15px;
    width: 60%;
    border: 2px solid var(--primary-text);
    border-radius: 10px;
    color: var(--primary-text);
    font-size: 2rem;
    outline: none;
}

.btnForm {
    margin-left: 0;
    width: 40%;
    font-weight: 600;
    background-color: var(--light);
    cursor: pointer;
}

.btnForm:hover {
    background-color: var(--primary-color);
}

.checkBox {
    padding: 5px;
    width: 42%;
}

.checkBox .labelCheckBox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
    color: var(--primary-text);
}

.checkBox .inputCheckBox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkBox .markCheckBox {
    position: absolute;
    top: 0;
    left: -10px;
    height: 25px;
    width: 25px;
    border: 1px solid var(--primary-text);
    border-radius: 5px;
    background-color: #ffffff;
}

.labelCheckBox:hover input ~ .markCheckBox {
    background-color: var(--primary-color);
}

.labelCheckBox input:checked ~ .markCheckBox {
    background-color: var(--primary-text);
}

.markCheckBox:after {
    content: "";
    position: absolute;
    display: none;
}

.labelCheckBox input:checked ~ .markCheckBox:after {
    display: block;
}

.labelCheckBox .markCheckBox:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.textarea {
    padding: 20px;
    font-size: 2.2rem;
}

@media only screen and (min-height: 1023px) {
    .box {
        height: 900px;
    }
}