.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    width: 1000px;
    height: 900px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: #fff;
    color: var(--primary-text);
}

.boxText {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 90%;
    color: black;
}

.boxText h1 {
    font-size: 3.2rem;
}

.boxText h2 {
    width: 100%;
    font-size: 2.4rem;
}

.boxText h3 {
    font-size: 2rem;
}

.boxText p {
    padding-left: 10px;
    width: 100%;
    font-size: 1.8rem;
}

.boxText table {
    width: 70%;
}

.boxText table td {
    height: 35px;
    text-align: left;
    border: none;
}

.box Button {
    margin-top: 20px;
}

