.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80%;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.formBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 80%;
}

.boxItem {
    display: flex;
    width: 48%;
}

.btnBox {
    display: flex;
    justify-content: center;
    width: 40%;
}

.boxAlert {
    font-size: 1.8rem;
    font-weight: 500;
}