.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #fff;
}

.btnBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 70%;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 90%;
    height: 90%;
}

.box h2 {
    margin: 0 auto 20px;
    font-size: 20px;
    text-transform: uppercase;
}

.box p {
    margin: 3px 0;
    font-size: 1.6rem;
}

.box p span {
    font-weight: 600;
}

.textBox {
    display: flex;
    flex-wrap: wrap;
}

.textBox p {
    margin-right: 20px;
}

.box .paragraph {
    margin: 4px auto;
}

.signature {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 70%;
}