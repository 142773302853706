.table th:first-child {
    min-width: 20px;
}

.tdAction {
    padding: 4px 0 4px 20px;
    width: 120px;
    border-bottom: 1px solid var(--dark);
}

.icon, .iconDelete {
    margin-right: 15px;
    cursor: pointer;
    font-size: 18px;
}

.iconDelete {
    color: #c50000;
    cursor: pointer;
}