.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.boxModal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 700px;
    height: 300px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.boxModal h1 {
    font-size: 2.8rem;
}

.boxBtn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 60%;
}