* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

:root {
    --light: rgba(236, 230, 230, 0.92);
    --dark: #212020;
    --error-color: #fd5151;
    --box-shadow: #2f2f2f;
    --primary-color: #6bb1e0;
    --secondary-collor: #fa8000;
    --primary-text: #003c56;
    font-size: 10px;
}

/*:root {*/
/*    --light: rgba(236, 230, 230, 0.92);*/
/*    --dark: #212020;*/
/*    --error-color: #fd5151;*/
/*    --box-shadow: #2f2f2f;*/
/*    --primary-color: #6bb1e0;*/
/*    --secondary-collor: #fa8000;*/
/*    --primary-text: #003c56;*/
/*    font-size: 10px;*/
/*}*/

body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

button {
    font-size: 2rem;
}

table {
    margin-top: 20px;
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 0 5px;
    height: 45px;
    text-align: center;
    font-size: 1.8rem;
    border: 1px solid var(--dark);
}

th {
    border-bottom: 3px solid var(--dark);
}

.containerApp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 1280px;
}

.btnPrimaryBig,
.btnPrimarySmall {
    padding: 10px 30px;
    font-size: 2rem;
    background-color: var(--primary-text);
    color: var(--primary-color);
    border: none;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: color .3s linear, background-color .3s linear;
}

.btnPrimaryBig {
    margin: 20px 10px;
    font-size: 2.4rem;
}

.btnPrimaryBig:hover,
.btnPrimarySmall:hover {
    color: var(--primary-text);
    background-color: var(--primary-color);
}

.btnPrimaryBig:disabled,
.btnPrimarySmall:disabled {
    background-color: grey;
    color: var(--primary-color);
    cursor: auto;
}

@media only screen and (min-width: 1440px) {
    .containerApp {
        max-width: 1600px;
    }

    button {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 1600px) {
    .containerApp {
        max-width: 1900px;
    }

    button {
        font-size: 2.4rem;
    }

    th, td {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 1900px) {
    .containerApp {
        max-width: 2560px;
    }

    button {
        font-size: 2.4rem;
    }

    th, td {
        font-size: 2rem;
    }
}
