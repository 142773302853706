.container {
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0;
    width: 800px;
    height: 480px;
    border: 5px solid var(--primary-text);
    border-radius: 25px;
    background-color: var(--light);
    color: var(--primary-text);
}

.h2 {
    margin-bottom: 10px;
    font-size: 2.6rem;
}

.errorText {
    font-size: 1.6rem;
    letter-spacing: .5px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

.formBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    width: 100%;
    height: 90%;
}

.boxItem {
    display: flex;
    width: 48%;
}

.btnBox {
    display: flex;
    justify-content: space-between;
    width: 50%;
}