.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: calc(100vh - 180px);
    color: var(--primary-text);

}

.boxForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    height: 60%;
}

.boxItem {
    width: 50%;
}

.boxItemBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    width: 50%;
}

.boxItemBtn p {
    font-size: 2rem;
}

.boxItemBtn button {
    padding: 10px 20px;
    width: 60%;
    height: 50px;
    text-align: left;
    border: 2px solid var(--primary-text);
    background-color: transparent;
    color: var(--primary-text);
    text-transform: none;
    letter-spacing: 0;
}

.boxBtn button {
    margin-top: 40px;
    width: 400px;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 4px;

}