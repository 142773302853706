.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.h1 {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%);
    font-size: 42px;
    color: var(--primary-text);
    text-shadow: 0 0 2px var(--box-shadow);
    text-transform: uppercase;
    letter-spacing: 2px;
}

.box {
    display: flex;
    width: 1000px;
    height: 500px;
}

.img {
    width: 50%;
    object-fit: contain;
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.boxInput {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 440px;
}


.icon {
    position: absolute;
    right: 12px;
    width: 25px;
    height: 25px;
    color: var(--dark);
    cursor: pointer;
}

.p {
    position: absolute;
    bottom: 62%;
    font-size: 20px;
}

.info {
    position: absolute;
    bottom: 25%;
    font-size: 1.8rem;
}