.atomSpinner, .atomSpinner * {
    box-sizing: border-box;
}

.atomSpinner {
    height: 120px;
    width: 120px;
    overflow: hidden;
}

.atomSpinner .spinnerInner {
    position: relative;
    display: block;
    height: 100%;
    width: 100%;
}

.atomSpinner .spinnerCircle {
    display: block;
    position: absolute;
    color: var(--primary-color);
    font-size: calc(120px * 0.24);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.atomSpinner .spinnerLine {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation-duration: 1s;
    border-left-width: calc(120px / 25);
    border-top-width: calc(120px / 25);
    border-left-color: var(--primary-color);
    border-left-style: solid;
    border-top-style: solid;
    border-top-color: transparent;
}

.atomSpinner .spinnerLine:nth-child(1) {
    animation: atom-spinner-animation-1 1s linear infinite;
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}

.atomSpinner .spinnerLine:nth-child(2) {
    animation: atom-spinner-animation-2 1s linear infinite;
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}

.atomSpinner .spinnerLine:nth-child(3) {
    animation: atom-spinner-animation-3 1s linear infinite;
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
    100% {
        transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-2 {
    100% {
        transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
    }
}

@keyframes atom-spinner-animation-3 {
    100% {
        transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
    }
}