.container {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: calc(100vh - 180px);
    color: var(--primary-text);
}

.topBox {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 8px 0;
}

.topBox p {
    padding: 10px 0;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: .5px;
}

.box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 460px;
    font-size: 1.8rem;
}

.infoBox {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 75%;
    height: 100%;
}

.carInfoBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 48%;
    height: 100%;
}

.menuBox {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: end;
    width: 25%;
    height: 100%;
}

.textCarInfoBox {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid grey;
}

.menuBox button {
    width: 220px;
}

.textCarInfoBox p {
    width: 45%;
}

span {
    font-weight: 500;
}

.bottomBox {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.equipmentBox {
    width: 100%;
    padding: 10px;
}

.equipmentBox ul {
    list-style: circle;
    column-count: 3;
    column-width: auto;
}

.equipmentBox ul li {
    padding-bottom: 6px;
}

.descriptionBox {
    padding-bottom: 40px;
    text-align: justify;
}

.branch {
    margin-top: 20px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: .5px;
    color: #0059bd;
}

.title {
    margin: 10px;
    font-size: 2rem;
}

.text {
    font-size: 1.8rem;
}

.reserved {
    width: 100%;
    height: 120px;
    font-size: 2.4rem;
    color: var(--error-color);
}

@media only screen and (min-width: 1600px) {
    .container {
        font-size: 2rem;
    }

}