.container {
    display: flex;
    width: 100%;
    border-bottom: 2px solid var(--primary-text);
    background-color: var(--primary-color);
    overflow: hidden;
}

.logo {
    width: 180px;
    height: 120px;
    background-image: url("../../images/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.box {
    width: calc(100% - 180px);
}

.boxTitle {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50px;
    box-shadow: 5px 0 5px var(--primary-text);
}

.h1 {
    width: 50%;
    font-size: 2.4rem;
    text-align: right;
    letter-spacing: 2px;
    color: #003c56;
}

.boxUser {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 34%;
    font-size: 2rem;
}

.user {
    margin: 0 15px;
}


.logOffIcon {
    color: red;
    cursor: pointer;
}

.nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 70px;
}

.menuList {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    list-style: none;
    font-size: 2rem;
}

.listItem,
.toggleSwitch {
    padding: 10px 15px;
    border: none;
    font-weight: 600;
    text-decoration: none;
    background-color: transparent;
    color: var(--primary-text);
    cursor: pointer;
}

.listItem {
    border-bottom: 3px solid transparent;
}

.toggleSwitch {
    font-size: 2rem;
    font-weight: 600;
    transition: color .3s linear;
}

.toggleSwitch:hover {
    color: #0054A8;
}

@media only screen and (min-width: 1440px) {
    .menuList {
        font-size: 2.2rem;
    }

    .toggleSwitch {
        font-size: 2.2rem;
    }
}

@media only screen and (min-width: 1600px) {
    .menuList {
        font-size: 2.4rem;
    }

    .toggleSwitch {
        font-size: 2.4rem;
    }
}

@media only screen and (min-width: 1900px) {
    .menuList {
        font-size: 2.6rem;
    }

    .toggleSwitch {
        font-size: 2.6rem;
    }
}